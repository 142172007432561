import type { CustomerReview } from 'components/content-blocks/customer-reviews/customer-reviews';

const CustomerReviewsCard = (review: CustomerReview, hasImage: boolean) => (
  <div className="reviews-card">
    {hasImage && !review.image.source_url.includes('NO_IMAGE') && (
      <div className="reviews-card-image">
        <img
          loading="lazy"
          src={ review.image.source_url }
          alt="customer headshot"
        />
      </div>
    )}
    <div className="reviews-card-content">
      <p className="reviews-card-text">{review.quote}</p>
      <p className="reviews-card-author">{review.name}</p>
      <p className="reviews-card-company">{review.company}</p>
    </div>
  </div>); 

export default CustomerReviewsCard;
