import { useModal } from 'react-modal-hook';

import ACFImage from 'types/ACFImage';

import ScheduleDemoModal from 'components/modal/schedule-demo/schedule-demo-modal';

import './styles/pricing.scss';

type HeroPricingProps = {
  background_desktop_image: ACFImage;

  copy: string;
  schedule_demo: {
    button_image: ACFImage;
    button_text: string;
  };
  sub_title: string;
};

export const HeroPricing = ({
  sub_title,
  copy,
  schedule_demo,
  background_desktop_image,
}:HeroPricingProps) => {
  const [showScheduleDemoModal, hideScheduleDemoModal] = useModal(() => (
    <ScheduleDemoModal isOpen={ true } onHide={ hideScheduleDemoModal } />
  ));
  return (
    <section
      className="pricing-hero-section hero-section"
      data-section="pricingHero"
    >
      <div className="container container-large">
        <div className="hero-wrapper is-flex-desktop">
          <header>
            <h1 className="subtitle">{sub_title}</h1>
            <h2 className="title" dangerouslySetInnerHTML={ { __html: copy } } />
            <button
              type="button"
              onClick={ showScheduleDemoModal }
              className="button button-link is-rounded"
            >
              {schedule_demo.button_text}
            </button>
          </header>
        </div>
      </div>
      <img
        className="background-image"
        src={ background_desktop_image.source_url }
        role="presentation"
      />
    </section>
  );
};
