import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import ACFImage from 'types/ACFImage';

import AwardBadges from 'components/globals/award-badges';

import '../styles/customer-reviews.scss';
import CapterraReviewsCard from './capterra-reviews-card';
import CustomerReviewsCard from './customer-reviews-card';

type CustomerReviewsProps = {
  capterraReviews: boolean
  customer?: [
    review: CustomerReview
  ];
  hasAwards: boolean;
  hasImage: boolean;
  reviews?: [
    review: CapterraReview
  ];
  sub_title: string;
  title: string;
};

export type CustomerReview = {
  company: string;
  hasImage: boolean
  image: ACFImage
  name: string;
  quote: string;
};

export type CapterraReview = {
  review: {
    capterra_logo: ACFImage,
    name: string;
    quote: string;
    review: string,
    title: string;
  }

};

const CustomerReviews = ({
  title,
  sub_title,
  customer,
  reviews,
  hasImage,
  hasAwards,

}:CustomerReviewsProps) => {
  const [itemIndex, setItemIndex] = useState(0); // default plan

  return (
    <section
      className={ `customer-reviews-section ${reviews ? 'capterra-reviews-section' : ''}` }
      data-section="reviews-section"
    >
      <div className="container container-xlarge">
        <div className="reviews">
          <header>
            <h2 className="title">{title}</h2>
            {reviews && <img src="https://marketing-assets.wheniwork-production.com/2022/10/11120130/stars.svg" loading="lazy" role="presentation" />}
            <h3 className="subtitle">{sub_title}</h3>
          </header>
          <div className={ `reviews-wrapper ${reviews ? 'capterra-reviews-wrapper' : ''}` }>
            {customer !== undefined &&
              customer?.map((review, index: number) => <CustomerReviewsCard key={ index } { ...review } hasImage={ hasImage } />
              )}
            {reviews !== undefined &&
              reviews?.map((review, index: number) => <CapterraReviewsCard key={ index } { ...review } />
              )}
          </div>
          <div className="reviews-wrapper is-swipeable">
            <div className="reviews-swipeable-wrapper">
              {customer !== undefined && <SwipeableViews
                className="reviews-swipeable-root"
                onChangeIndex={ i => setItemIndex(i) }
                slideClassName="reviews-card-slide card-slide"
                springConfig={ {
                  easeFunction: 'cubic-bezier(0, 0, 0.24, 0.96)',
                  duration: '.6s',
                  delay: '0s',
                } }
                enableMouseEvents
              >
                { customer?.map((review, index: number) => <CustomerReviewsCard key={ index } { ...review } hasImage={ hasImage } />)}
              </SwipeableViews>}
              {reviews !== undefined && <SwipeableViews
                className="reviews-swipeable-root"
                onChangeIndex={ i => setItemIndex(i) }
                slideClassName="reviews-card-slide card-slide"
                springConfig={ {
                  easeFunction: 'cubic-bezier(0, 0, 0.24, 0.96)',
                  duration: '.6s',
                  delay: '0s',
                } }
                enableMouseEvents
              >
                { reviews?.map((review, index: number) => <CapterraReviewsCard key={ index } { ...review } />)}
              </SwipeableViews>}
            </div>
            <div className="slider-dots">
              {customer &&
                customer?.map((_, index) => (
                  <div
                    key={ index }
                    className={ `slider-dot ${index === itemIndex &&
                      'filled'} secondary` }
                  />
                ))}
              {reviews &&
                reviews?.map((_, index) => (
                  <div
                    key={ index }
                    className={ `slider-dot ${index === itemIndex &&
                      'filled'} secondary` }
                  />
                ))}
            </div>
          </div>
          {hasAwards && <AwardBadges />}
        </div>
      </div>
    </section>
  );
};

export default CustomerReviews;
