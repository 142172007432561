import { Component } from 'react';

import './styles/roi-calculator.scss';

class SavingsCalculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: 25,
      hours: 20,
      manager_rate: 12.5,
      savings_first_month: 0,
      savings_monthly_post_setup: 0,
      savings_first_year: 0,
      monthly_rate: 62.5,
    };
  }

  componentDidMount() {
    this.updateSavingsTable();
  }

  setHours(e) {
    this.setState({ hours: e.target.value }, () => {
      this.updateSavingsTable();
    });
  }

  setManagerRate(e) {
    this.setState({ manager_rate: e.target.value }, () => {
      this.updateSavingsTable();
    });
  }

  setUsers(e) {
    this.setState({ users: e.target.value }, () => {
      const newRate = this.getMonthlyRate();
      this.setState({ monthly_rate: newRate }, () => {
        this.updateSavingsTable();
      });
    });
  }

  getMonthlyRate() {
    const PER_USER_RATE_STANDARD = 2.5;
    const PER_USER_RATE_ADVANCED = 6;

    const perUserRate = this.state.users <= 500 ? PER_USER_RATE_STANDARD : PER_USER_RATE_ADVANCED;

    return perUserRate * this.state.users;
  }

  formatMoney(value) {
    return Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  calculateFirstMonthSavings() {
    const e = 0.2 * (this.state.hours * this.state.manager_rate * 4.3452);
    return e - this.state.monthly_rate > 0 ? e - this.state.monthly_rate : 0;
  }

  calculatePostSetupMonthlySavings() {
    const e = 0.5 * (this.state.hours * this.state.manager_rate * 4.3452);
    return e - this.state.monthly_rate > 0 ? e - this.state.monthly_rate : 0;
  }

  updateSavingsTable() {
    const fMonthSavings = this.calculateFirstMonthSavings();
    const postSetupMonthlySavings = this.calculatePostSetupMonthlySavings();

    this.setState({
      savings_first_month: fMonthSavings,
      savings_monthly_post_setup: postSetupMonthlySavings,
      savings_first_year: fMonthSavings + 11 * postSetupMonthlySavings,
    });
  }

  render() {
    return (
      <section data-section="calculator-section" className="rio-calculator-section section">
        <div className="container container-xlarge" id="roi-calculator">
          <section className="roi-calculator-section">
            <div className="columns is-marginless">
              <div className="column is-paddingless has-text-centered">
                <h2 className="title">See how much your time is worth.</h2>
              </div>
            </div>
            <div className="savings-calc">
              <div className="savings-calc-column first">
                <header>
                  <img
                    loading="lazy"
                    src="https://marketing-assets.wheniwork-production.com/2019/12/04173342/icon-schedule.svg"
                    alt="fill me out"
                  />
                  <label className="roi-icon-label">
                    Hours Spent Scheduling/Week
                  </label>
                </header>
                <div className="savings-calc-content">
                  <input
                    className="input"
                    name="manager_hours"
                    type="number"
                    value={ this.state.hours }
                    onChange={ this.setHours.bind(this) }
                    placeholder="20"
                    step=".1"
                    min="0"
                  />
                  <p className="description">
                    Total hours managers spend building the schedule + additional
                    time spent managing the schedule after publishing.
                  </p>
                </div>
                <footer className="first">
                  <label className="stat-label">First Month Savings</label>
                  <h2 className="result-stat">
                    ${this.formatMoney(this.state.savings_first_month)}
                  </h2>
                </footer>
              </div>
              <div className="savings-calc-column">
                <header>
                  <img
                    loading="lazy"
                    src="https://marketing-assets.wheniwork-production.com/2019/12/04173343/icon-schedule-money.svg"
                    alt="fill me out"
                  />
                  <label className="roi-icon-label">
                    Average Manager Hourly Rate
                  </label>
                </header>
                <div className="savings-calc-content">
                  <input
                    className="input"
                    name="manager_rate"
                    type="number"
                    value={ this.state.manager_rate }
                    onChange={ this.setManagerRate.bind(this) }
                    placeholder="20"
                    step=".01"
                    min="0"
                  />
                  <p className="description">
                    Enter the average rate your <br /> managers earn.
                  </p>
                </div>
                <footer>
                  <label className="stat-label">
                    Monthly Savings After Setup
                  </label>
                  <h2 className="result-stat">
                    ${this.formatMoney(this.state.savings_monthly_post_setup)}
                  </h2>
                </footer>
              </div>
              <div className="savings-calc-column last">
                <header>
                  <img
                    loading="lazy"
                    src="https://marketing-assets.wheniwork-production.com/2019/12/04173343/icon-schedule-two-people.svg"
                    alt="fill me out"
                  />
                  <label className="roi-icon-label">Number of Users</label>
                </header>
                <div className="savings-calc-content">
                  <input
                    className="input"
                    name="users"
                    type="number"
                    value={ this.state.users }
                    onChange={ this.setUsers.bind(this) }
                    placeholder="25"
                    step="1"
                    min="0"
                  />
                  <p className="description">
                    Enter the total number of users that will be using When I Work
                    including managers.
                  </p>
                </div>
                <footer className="last">
                  <label className="stat-label">First Year Savings</label>
                  <h2 className="result-stat">
                    ${this.formatMoney(this.state.savings_first_year)}
                  </h2>
                </footer>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <p className="disclaimer">
                  Savings calculated based on an average time savings of 20%
                  during your first month with When I Work and an average time
                  savings of 50% for all following months. We have subtracted the
                  monthly cost of When I Work from our savings calculation.
                </p>
              </div>
            </div>
            <div className="savings-calc-bg"></div>
          </section>
        </div>
      </section>
    );
  }
}

export default SavingsCalculator;
