import { Fragment, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import useMixpanel from 'utils/hooks/use-mixpanel';

import ButtonToggleSwitch from 'components/buttons/button-toggle-switch';
import FontIcon from 'components/icons';

import './styles/plan-comparison-v2.scss';

type PlanComparisonProps = {
  activateAttendance: boolean,
  fdt387: string|boolean|undefined,
  packagingComparison: {
    features_button_text_state_1: string,
    features_button_text_state_2: string,
    section: Section[],
    title: string
  },
  toggleAttendance: () => void
}

type Section = {
  feature: [FeatureRow],
  header: {
    name: string,
    plan_1: string,
    plan_2: string,
    plan_3: string,
    plan_4: string,
  }
}

type FeatureRow = {
  name: string,
  plan_1: boolean,
  plan_2: boolean,
  plan_3: boolean,
  plan_4: boolean,
}

const PlanComparisonV2 = ({
  activateAttendance,
  toggleAttendance,
  packagingComparison,
  fdt387,
}:PlanComparisonProps) => {
  const mixpanel = useMixpanel();
  const initialFeatures = [packagingComparison.section[0]];
  const allFeatures = packagingComparison.section;
  const breakpoints = useBreakpoint();
  const [toggleFeatures, setToggleFeatures] = useState(initialFeatures);
  const attendanceToggle = (data, index) => data === 'Time Tracking & Attendance' ?
    (<ButtonToggleSwitch
      updateToggleState={ toggleAttendance }
      toggleState={ activateAttendance }
      id={ `priceSwitch${index}` }
      label="Add Time &amp; Attendance" />) :
    null;
  const processContent = (content: boolean, sectionName: string) => {
    if (content) {
      return sectionName === 'Time Tracking & Attendance' && !activateAttendance ? <FontIcon className="grey-check" icon="checkmark" /> : <FontIcon icon="checkmark" />;
    } else {
      return null;
    }
  };
  const updateFeatures = () => {
    mixpanel.track('Click', { 'Click target': 'See All Features', 'Component': 'Marketing Pricing Page' });
    toggleFeatures.length === 1 ? setToggleFeatures(allFeatures) : setToggleFeatures(initialFeatures);
  };

  const renderFullTable = () => {
    return (
      <section className={ `plan-comparison-section-v2 attendance-${activateAttendance}` } id="features" data-section="plan comparison component">
        <div className="container">
          <h2 className="title has-text-centered">{packagingComparison.title}</h2>
          {toggleFeatures.map((section, index) => (
            <table
              key={ index }
              className={ `table is-fullwidth is-striped table-${index} ${toggleFeatures.length === 1 ? 'fog-machine-on' : 'tables-visible'}` }
            >
              <thead>
                <tr>
                  <th>{section.header.name}{attendanceToggle(section.header.name, index)}</th>
                  {fdt387 && <th>{section.header.plan_1}</th>}
                  <th>{section.header.plan_2}</th>
                  <th>{section.header.plan_3}</th>
                  <th>{section.header.plan_4}</th>
                </tr>
              </thead>
              <tbody>
                {section.feature.map((featureRow, index) => (
                  <tr key={ index }>
                    <td>{featureRow.name}</td>
                    {fdt387 && <td>{processContent(featureRow.plan_1, section.header.name)}</td>}
                    <td>{processContent(featureRow.plan_2, section.header.name)}</td>
                    <td>{processContent(featureRow.plan_3, section.header.name)}</td>
                    <td>{processContent(featureRow.plan_4, section.header.name)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
          <button
            type="button"
            className="button is-rounded features-toggle-button"
            onClick={ () => updateFeatures() }>
            {toggleFeatures.length !== 1 ? packagingComparison.features_button_text_state_2 : packagingComparison.features_button_text_state_1}
            <FontIcon icon="chevron-up" className={ `icon-chevron-up ${toggleFeatures.length !== 1 ? 'is-flipped' : ''}` } />
          </button>
        </div>
      </section>
    );
  };

  const renderMobileTable = () => {
    return (
      <section className={ `plan-comparison-section-v2 attendance-${activateAttendance}` } id="features" data-section="plan comparison component">
        <div className="container">
          <h2 className="title has-text-centered">{packagingComparison.title}</h2>
          {toggleFeatures.map((section, index) => (
            <table
              key={ index }
              className={ `table is-fullwidth is-mobile table-${index} ${toggleFeatures.length === 1 ? 'fog-machine-on' : 'tables-visible'}` }
            >
              <thead>
                <tr className="section-header">
                  <td colSpan={ fdt387 ? 4 : 3 }>{section.header.name}{attendanceToggle(section.header.name, index)}</td>
                </tr>
                <tr className="section-header-labels">
                  {fdt387 && <th className="feature-header">{section.header.plan_1}</th>}
                  <th className="feature-header">{section.header.plan_2}</th>
                  <th className="feature-header">{section.header.plan_3}</th>
                  <th className="feature-header">{section.header.plan_4}</th>
                </tr>
              </thead>
              <tbody>
                {section.feature.map((featureRow, index) => (
                  <Fragment key={ `feature-${index}` }>
                    <tr className="feature-description">
                      <td colSpan={ fdt387 ? 4 : 3 }>{featureRow.name}</td>
                    </tr>
                    <tr>
                      {fdt387 && <td className="feature-check">{processContent(featureRow.plan_1, section.header.name)}</td>}
                      <td className="feature-check">{processContent(featureRow.plan_2, section.header.name)}</td>
                      <td className="feature-check">{processContent(featureRow.plan_3, section.header.name)}</td>
                      <td className="feature-check">{processContent(featureRow.plan_4, section.header.name)}</td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          ))}
          <button
            type="button"
            className="button is-rounded features-toggle-button"
            onClick={ () => updateFeatures() }>
            {toggleFeatures.length !== 1 ? packagingComparison.features_button_text_state_2 : packagingComparison.features_button_text_state_1}
            <FontIcon icon="chevron-up"
              className={ `icon-chevron-up ${toggleFeatures.length !== 1 ? 'is-flipped' : ''}` } />
          </button>
        </div>
      </section>
    );
  };

  if (breakpoints.sm) {
    return renderMobileTable();
  } else {
    return renderFullTable();
  }

};

export default PlanComparisonV2;