import { CapterraReview } from './customer-reviews';

const CapterraReviewsCard = ({ review }: CapterraReview) => (
  <div className="reviews-card">
    <header className="review-header">
      <strong className="review-name">{review.name}</strong>
      <img
        className="review-stars"
        src="https://marketing-assets.wheniwork-production.com/2019/12/30195533/messaging-5-stars.svg"
        loading="lazy"
        role="presentation"
      />
    </header>

    <p className="review-title">{review.title}</p>
    <blockquote className="quote">{review.quote}</blockquote>
    <p className="review">{review.review}</p>
    <img
      className="capterra-logo"
      src={ review.capterra_logo.source_url }
      loading="lazy"
      role="presentation"
    />
  </div>
);

export default CapterraReviewsCard;
